import dynamic from "next/dynamic";
import { dehydrate, QueryClient, useQuery } from '@tanstack/react-query';
import { getQueryFn, queryKeyManager} from "../utils/api/queryFunction";
import queryConfig from "../utils/api/queryConfig";

const SEO = dynamic(() => import('../components/Shared/SEO'));
//Get page builder
const DefaultLayout = dynamic(() => import('../components/Layout/DefaultLayout'));
const CarouselBanner = dynamic(() => import('../components/Shared/Banners/CarouselBanner'));
const CategoryBuilder = dynamic(() => import('../components/Shared/Builders/CategoryBuilder'));

const Page = (props) => {
	const { token } = props;
	const { isLoading, isError, data } = useQuery({
		queryKey: queryKeyManager(['homepage'], [token]),
		queryFn: () => getQueryFn('homepage', token),
		...queryConfig
	})
	if (isLoading) {
		return <span />
	}
	return (
		<>
			<SEO seo={data.seo} />
			<DefaultLayout nav={data.nav.header.links} logo={data.nav.header.logos} footer={data.nav.footer}>
				{data.slider && data.slider.banners !== null && data.slider.banners.length > 0 &&
					<div className="categoryBuilderHolder">
						<CarouselBanner
							banners={data.slider}
							pageData={data}							
						/>
					</div>
				}
				<div className="categoryBuilderHolder">
					<CategoryBuilder
						data={data}
					/>
				</div>
			</DefaultLayout>
		</>
	);
};

Page.getInitialProps = async ({ query, req }) => {
	const queryClient = new QueryClient();
	const { token } = query;
	await queryClient.prefetchQuery({
		queryKey: queryKeyManager(['homepage'], [token]),
		queryFn: () => getQueryFn('homepage', token),
		...queryConfig
	});
	return {
		token: token,
		dehydratedState: dehydrate(queryClient)
	};
};

export default Page;